<template>
  <div class="accountset registermain">
    <userheader menucurrent=2 />
    <div class="page-main">
      <div class="w">
        <div class="panel">
          <div class="panel-body">
            <div class="panel-heading">
              <div class="mt step">
                <el-steps :active="stepcurrent" align-center>
                  <el-step title="联系人" description=""></el-step>
                  <el-step title="主体信息" description=""></el-step>
                  <el-step title="结算信息" description=""></el-step>
                  <el-step title="公司调研信息" description=""></el-step>
                  <el-step title="公司信息" description=""></el-step>
                </el-steps>
              </div>
            </div>
            <div class="panel-body">
              <slot name="content">

              </slot>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
export default {
  name: 'applymain',
  props: ['stepcurrent'],
  components: {
    userheader: userheader,
    userfooter: userfooter,
  },
  data() {
    return {
      activeName: 'second'
    };
  },
}
</script>
<style scoped>
/**公共css开始**/
body {
  background-color: #fff;
  /* height: 100%; */
}
.clearfix {
  clear: both;
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
body {
  background: #fff;
}
.t-r {
    text-align: right;
}
.w {
  width: 990px;
  margin: 0 auto;
}
.mt,
.smt {
  cursor: default;
}
.t-c{
  text-align: center;
}
.m,
.mt,
.mc,
.mb,
.sm,
.smt,
.smc,
.smb {
  overflow: hidden;
  zoom: 1;
}
.clearfix {
  clear: both;
}
.btn-group {
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
<style scoped>

.panel {
  border: 1px solid #dfdfdf;
  border-top: 2px solid #7c7c7e;
  margin-top: 30px;
  margin-bottom: 30px;
}
.panel .panel-body {
  padding: 10px;
}
.panel .panel-heading {
  padding: 7px 10px;
  background: #fafafa;
  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;
}
.step >>> .el-step__title {
  font-size: 12px;
}
.step >>> .el-step__icon.is-text {
  border-width: 1px;
}
.step >>> .el-step__icon {
  width: 18px;
  height: 18px;
}
.step >>> .el-step__icon-inner {
  font-weight: normal;
}
.step >>> .el-step.is-horizontal .el-step__line {
  top: 8px;
}
.step>>>.el-step__title.is-finish,.step>>>.el-step__head.is-finish{
  color:#45a2e1;
  
}
.step>>>.el-step__head.is-finish{
  border-color:#45a2e1;
}
.step>>>.el-step__icon.is-text{
  background: #ccc;
  color: #fff;
}
.step>>>.el-step__title.is-process{
  color: #ccc;
  font-weight: normal;
}
.step>>>.el-step__head.is-process{
    color: #ccc;
    border-color: #ccc;
}
.step>>>.is-finish .el-step__icon.is-text{
  background: #45a2e1;
  color: #fff;
}
</style>